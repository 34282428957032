import { Image } from "components/contentful/image/Image";
import { Container } from "components/container/Container";
import React from "react";
import s from "./PartnersGrid.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import richTextOptions from "utils/richTextOptions";
import { GatsbyImage } from "gatsby-plugin-image";

const GridColumn = ({ item }: any) => {
  const items = item.items;

  return (
    <div className={s.column}>
      {items.map((el: any, index: number) => {
        if (index < items.length - 1) {
          return (
            <div className={s.logoContainer}>
              <Image className={s.logo} image={el.image} />
            </div>
          );
        } else {
          return (
            <div className={s.infoContainer}>
              <h3 className={s.title}>{el.title}</h3>
              <div className={s.text}>
                {renderRichText(el.text, richTextOptions)}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export const PartnersGrid = ({ listData }: any) => {
  const columns = listData.subLists;

  return (
    <Container>
      <div className={s("container")}>
        {columns.map((item: any, index: number) => {
          return <GridColumn item={item} key={index} />;
        })}
      </div>
    </Container>
  );
};
